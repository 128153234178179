import { config } from "./TrackConfiguration.js";
import { colors } from "./colors.js";

export const climateUI = {
    setup: function () {
        // Setup specific to climate track can go here
        // For example, initializing charts or elements that are static throughout the lifecycle of the application
    },

    update: function (data) {
        // Update Total Emissions
        d3.select("#emissions").text(data.emissions ? `${numeral(data.emissions).format('0.00')}Mt CO2e` : "N/A");
        
        // Update Emissions Per Capita
        d3.select("#emissionsPerCapita").text(data.emissionsPerCapita ? `${numeral(data.emissionsPerCapita).format('0.00a')}t CO2e` : "N/A");
        
        // Update Change Since 1990
        d3.select("#changeSince1990").text(data.changeSince1990 ? numeral(data.changeSince1990).format('+0.0a') + "%" : "N/A");
        
        // Update Climate Performance Index
        d3.select("#performanceIndex").text(data.performanceIndex ? numeral(data.performanceIndex).format('0a') + "/200" : "N/A");
        
        // Update Climate Risk Index
        d3.select("#riskIndex").text(data.riskIndex ? numeral(data.riskIndex).format("0.0a") : "N/A");
        
        // Update Percentage of Renewable Electricity
        // if percentage is 100, no decimals
        d3.select("#percentageRenewableElectricity").text(data.percentageRenewableElectricity ? `${data.percentageRenewableElectricity == 100 ? numeral(data.percentageRenewableElectricity).format('0') : numeral(data.percentageRenewableElectricity).format('0.0')}%` : "N/A");
        drawPie(data.percentageRenewableElectricity || 0);

        // Update CO2 Emissions per Dollar
        d3.select("#kgCO2PerDollar").text(data.kgCO2PerDollar ? `${data.kgCO2PerDollar} kg/$` : "N/A");

        // For metrics that require visualizations (like charts), you would update them here
        // Example for a chart update function:
        // updateEmissionsChart(data.emissions);

        // Update the context section
        let insights = data.insights || "N/A";
        if (data.read_more) {
            insights += ` <a href="${data.read_more}" target="_blank">Read more on Wikipedia.</a>`;
        }
        d3.select("#climateContext").html(insights);
    }
}


function drawPie(percentage) {
    let width = 152;
    let height = 92;
    let radius = Math.min(width, height) / 2;
    let color = config['climate'].colorScales.percentageRenewableElectricity;

    let svg = d3.select("#renewableElectricityChart")
        .attr("width", width)
        .attr("height", height);

    let pie = d3.pie()
        .sort(null)
        .value(d => d);

    let arc = d3.arc()
        .outerRadius(radius+5)
        .innerRadius(radius - 5);

    let data = [percentage, 100 - percentage];

    let g = svg.append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    let arcs = g.selectAll(".arc")
        .data(pie(data))
        .enter().append("g")
        .attr("class", "arc");

    arcs.append("path")
        .attr("d", arc)
        .style("fill", (d, i) => {
            if (i == 0) {
                return color(percentage);
            } else {
                return colors.grey;
            }
        });
}

