// Define the base colors without brightening them
/*const colorObjects = {
    red: chroma('#e15241'),
    pink: chroma('#d63864'),
    purple: chroma('#9031aa'),
    deepPurple: chroma('#613cb0'),
    indigo: chroma('#4350af'),
    blue: chroma('#4994ec'),
    lightBlue: chroma('#4350af'),
    cyan: chroma('#54b9d1'),
    teal: chroma('#429488'),
    green: chroma('#66ad5b'),
    lightGreen: chroma('#97c25c'),
    lime: chroma('#d0dc59'),
    yellow: chroma('#fcec60'),
    amber: chroma('#f5c444'),
    orange: chroma('#f19d39'),
    deepOrange: chroma('#ec6338'),
    brown: chroma('#74574a'),
    grey: chroma('#9e9e9e'),
    blueGrey: chroma('#667c89')
};*/

// new version
/*  blue: #5778a4,
  orange: #e49444,
  red: #d1615d,
  teal: #85b6b2,
  green: #6a9f58,
  yellow: #e7ca60,
  purple: #a87c9f,
  pink: #f1a2a9,
  brown: #967662,
  grey: #b8b0ac,*/
const colorObjects = {
    blue: chroma('#5778a4'),
    orange: chroma('#e49444'),
    red: chroma('#d1615d'),
    teal: chroma('#85b6b2'),
    green: chroma('#6a9f58'),
    yellow: chroma('#e7ca60'),
    purple: chroma('#a87c9f'),
    pink: chroma('#f1a2a9'),
    brown: chroma('#967662'),
    grey: chroma('#b8b0ac'),
  };

export const colors = {
    ...Object.keys(colorObjects).reduce((acc, color) => {
        acc[color] = colorObjects[color].hex();
        return acc;
    }, {}),
    light: (color, i=1.5) => {
        return colorObjects[color].brighten(i).hex();
    },
    dark: (color, i=1) => {
        return colorObjects[color].darken(i).hex();
    }
};



