import { config } from "./TrackConfiguration.js";

export const democracyUI = {
    setup: function () {
        let democracyIndex = d3.select("#democracyIndex");
        let xScale = d3.scaleLinear().domain([0, 10]).range([0, 380]);
        let xAxis = d3.axisBottom(xScale).tickValues([0, 2, 4, 6, 8, 10])
            .tickSize(-5) // Make the ticks face upwards

        democracyIndex.append("g")
            .attr("transform", "translate(0,5)")
            .call(xAxis);

        democracyIndex.append("rect")
            .attr("id", "democracyIndexRect")
            .attr("y", 0)
            .attr("height", 5);


    },
    update: function (data) {
        d3.select("#governmentType").text(data.governmentType);
        d3.select("#executiveRoles").text(data.executiveRoles);

        d3.select("#democracyIndexNumber").text(data.democracyIndex || "N/A");
        d3.select("#democracyIndexRegimeType").text(data.regimeType || "");
        drawBar(data.democracyIndex || 0);

        d3.select("#effectivePartyNumber").text(data.effectivePartyNumber || "N/A");
        drawCircles(data.effectivePartyNumber);

        d3.select("#womenInParliamentNumber").text(data.womenPercentage ? data.womenPercentage + "%" : "N/A");
        d3.select("#womenInParliamentYear").text(data.lastElections ? "(" + new Date(data.lastElections).getFullYear() + ")" : "");
        drawPie(data.womenPercentage || 0);

        d3.select("#voterTurnoutNumber").text(data.voterTurnout_parl ? data.voterTurnout_parl + "%" : "N/A");
        d3.select("#voterTurnoutYear").text(data.votingYear_parl ? "(" + new Date(data.votingYear_parl).getFullYear() + ")" : "");
        drawGauge(data.voterTurnout_parl || 0);

        let insights = data.insights || "N/A";
        if (data.read_more) {
            insights += ` <a href="${data.read_more}" target="_blank">Read more on Wikipedia.</a>`;
        }
        d3.select("#democracyContext").html(insights)
    }

}




function drawBar(index) {
    let svg = d3.select("#democracyIndex");
    let xScale = d3.scaleLinear().domain([0, 10]).range([0, 380]);

    // Update the width of the rectangle
    svg.select("#democracyIndexRect")
        .transition()
        .duration(100)
        .attr("width", xScale(index))
        .attr("fill", config.democracy.colorScales.democracyIndex(index));
}

function drawCircles(count) {
    let wholePart = Math.floor(count);
    let fractionalPart = count % 1;

    let data = Array(wholePart).fill(1);
    if (fractionalPart !== 0) data.push(fractionalPart);

    let svg = d3.select("#effectivePartyBar");

    svg.selectAll("circle").remove();
    let radius = 7;
    const maxWidth = 176;
    radius = Math.min(radius * 2.5, maxWidth / count) / 2.5;
    let spacing = radius * 0.5;


    svg.selectAll("circle")
        .data(data)
        .enter()
        .append("circle")
        .attr("cx", (d, i) => i * (radius * 2 + spacing) + radius) // Position the circles
        .attr("cy", radius)
        .attr("r", d => d * radius) // Set the radius based on the data
        // fill with color based on the effectivePartyNumberScale
        .attr("fill", config.democracy.colorScales.effectivePartyNumber(count));
}


function drawPie(percentage) {
    let width = 176;
    let height = 176;
    let radius = Math.min(width, height) / 2;
    let color = d3.scaleOrdinal(["#98abc5", "#8a89a6"]);

    let svg = d3.select("#womenInParliament")
        .attr("width", width)
        .attr("height", height);

    let pie = d3.pie()
        .sort(null)
        .value(d => d);

    let arc = d3.arc()
        .outerRadius(radius - 10)
        .innerRadius(radius - 30);

    let data = [percentage, 100 - percentage];

    let g = svg.append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    let arcs = g.selectAll(".arc")
        .data(pie(data))
        .enter().append("g")
        .attr("class", "arc");

    arcs.append("path")
        .attr("d", arc)
        .style("fill", (d, i) => color(i));
}

function drawGauge(turnout) {
    let width = 176;
    let height = 70;
    let radius = Math.max(width, height) / 2;

    let svg = d3.select("#voterTurnout")
        .attr("width", width)
        .attr("height", height);

    let scale = d3.scaleLinear()
        .range([-Math.PI / 2, Math.PI / 2])
        .domain([0, 100]);

    let arc = d3.arc()
        .innerRadius(radius - 28)
        .outerRadius(radius - 22)
        .startAngle(-Math.PI / 2);

    svg.append("path")
        .datum({ endAngle: scale(100) })
        .attr("d", arc)
        .attr("transform", "translate(" + width / 2 + "," + height + ")")
        .style("fill", "#ccc");

    svg.append("path")
        .datum({ endAngle: scale(turnout) })
        .attr("d", arc)
        .attr("transform", "translate(" + width / 2 + "," + height + ")")
        .style("fill", "#98abc5");
}
