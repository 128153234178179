import { colors } from "./colors.js";
import { democracyUI } from "./democracyUI.js";
import { climateUI } from "./climateUI.js";
import { securityUI } from "./securityUI.js";
import { economyUI } from "./economyUI.js";

function democracyConfig() {
    const metrics = ["governmentType", "democracyIndex", "voterTurnout_parl", "womenPercentage", "effectivePartyNumber", "democracyContext"];
    const ranges = {
        "governmentType": ["Parliamentary republic", "Presidential republic", "Semi-presidential republic", "Constitutional monarchy", "Semi-constitutional monarchy", "Absolute monarchy", "One-party state", "Military junta", "Transitional government", "Other"],
        "regimeType": ["Full democracy", "Flawed democracy", "Hybrid regime", "Authoritarian"],
        "democracyIndex": [0, 10],
        "voterTurnout_parl": [0, 100],
        "womenPercentage": [0, 50],
        "effectivePartyNumber": [2, 10]
    };
    const legendRanges = {
        ...ranges
    }
    legendRanges["democracyIndex"] = ranges["regimeType"];
    const explanations = {
        "democracyIndex": {
            explanation: "The EIU Democracy Index is a score out of 10 that assesses the state of democracy worldwide. It is based on five categories: electoral process and pluralism, civil liberties, the functioning of government, political participation, and political culture.",
            source: "https://pages.eiu.com/rs/753-RIQ-438/images/Democracy-Index-2023-Final-report.pdf",
            readMore: "https://en.wikipedia.org/wiki/The_Economist_Democracy_Index"
        },
        "voterTurnout_parl": {
            explanation: "Voter turnout for parliamentary elections, expressed as a percentage of the Voting Age Population (VAP), which includes all citizens who are legally eligible to vote, regardless of whether they are registered.",
            source: "https://www.idea.int/data-tools/data/question?question_id=9191&database_theme=293",
            readMore: "https://en.wikipedia.org/wiki/Voter_turnout"
        },
        "womenPercentage": {
            explanation: "The proportion of parliamentary seats occupied by women. It indicates the level of gender representation and equality in the political arena.",
            source: "https://web.archive.org/web/20240326083314/https://data.ipu.org/women-ranking?month=2&year=2024",
            readMore: "https://en.wikipedia.org/wiki/Women_in_government"
        },
        "effectivePartyNumber": {
            explanation: "The Effective Number of Political Parties is a measure of the number of parties that have a significant impact on politics in the country. It accounts for both the number and size of parties.",
            source: "https://www.tcd.ie/Political_Science/about/people/michael_gallagher/ElSystems/Docts/ElectionIndices.pdf",
            readMore: "https://en.wikipedia.org/wiki/Effective_number_of_parties"
        },
        "governmentType": {
            explanation: "The type of government system in place, such as parliamentary republic, presidential republic, constitutional monarchy, etc., together with the executive roles that exist within the system.",
            source: "https://en.wikipedia.org/wiki/List_of_countries_by_system_of_government#Systems_of_governance",
            readMore: "https://en.wikipedia.org/wiki/List_of_countries_by_system_of_government"
        },
        "democracyContext": {
            "explanation": "This text was generated using GPT3.5. The goal is to provide deeper insights into the country's political and social landscape. It blends data analysis with current events to give a comprehensive view. As it was automatically generated it may be subject to inaccuracies or be outdated. If you find any issues, please report them so we can improve the accuracy and relevance of our content."
        }
    };
    const colorScales = {
        "regimeType": d3.scaleOrdinal()
            .domain(ranges.regimeType)
            .range(["#3a5bce", "#85d0dd", "#f4d571", "#9b3227"]),

        "democracyIndex": d3.scaleThreshold()
            .domain([1, 2, 3, 4, 5, 6, 7, 8, 9])
            .range([
                "#200211", // 0.00-0.99
                "#5d0e13", // 1.00-1.99
                "#9b3227", // 2.00-2.99
                "#ce8632", // 3.00-3.99
                "#f4d571", // 4.00-4.99
                "#f9f8c2", // 5.00-5.99
                "#cbecec", // 6.00-6.99
                "#85d0dd", // 7.00-7.99
                "#3a5bce", // 8.00-9.00
                "#162f8b" // 9.00-10.00
            ]),

        "effectivePartyNumber": d3.scaleLinear()
            .domain([2, 3, 7])
            .range([colors.light("teal"), colors.teal, colors.dark("teal")]),

        "governmentType": d3.scaleOrdinal(d3.schemeTableau10)
            .domain(ranges.governmentType),

        "womenPercentage": d3.scaleLinear()
            .domain(ranges.womenPercentage)
            .range([colors.light("green"), colors.dark("green")]),

        "voterTurnout_parl": d3.scaleLinear()
            .domain(ranges.voterTurnout_parl)
            .range([colors.light("yellow", 3), colors.dark("yellow", 3)])
    };
    const legendColorScales = {
        "democracyIndex": colorScales.regimeType,
        "voterTurnout_parl": colorScales.voterTurnout_parl,
        "regimeType": colorScales.regimeType,
        "effectivePartyNumber": colorScales.effectivePartyNumber,
        "womenPercentage": colorScales.womenPercentage,
        "governmentType": colorScales.governmentType
    };

    const legendTitles = {
        "democracyIndex": "EIU Democracy Index 2023",
        "voterTurnout_parl": "Voter Turnout (VAP) in Last Parliamentary Elections in %",
        "womenPercentage": "Percentage of Women in Parliament",
        "effectivePartyNumber": "Effective Number of Political Parties",
        "governmentType": "Government Type"
    };

    const isContinuous = {
        "democracyIndex": false,
        "voterTurnout_parl": true,
        "womenPercentage": true,
        "effectivePartyNumber": true,
        "governmentType": false,
        "regimeType": false
    };

    const displayedLinear = {
        "democracyIndex": true,
        "voterTurnout_parl": true,
        "womenPercentage": true,
        "effectivePartyNumber": true,
        "governmentType": true,
        "regimeType": true
    };

    return {
        metrics: metrics,
        ranges: ranges,
        legendRanges: legendRanges,
        explanations: explanations,
        colorScales: colorScales,
        legendColorScales: legendColorScales,
        legendTitles: legendTitles,
        isContinuous: isContinuous,
        displayedLinear: displayedLinear,
        UI: democracyUI
    }
}


function climateConfig() {
    const metrics = ["emissions", "emissionsPerCapita", "changeSince1990", "performanceIndex", "riskIndex", "percentageRenewableElectricity", "kgCO2PerDollar", "climateContext"];
    const ranges = {
        "emissions": [10, 15000],
        "emissionsPerCapita": [0, 30],
        "changeSince1990": [-50, 100, 300],
        "performanceIndex": [0, 100, 200],
        "riskIndex": [0, 125],
        "percentageRenewableElectricity": [0, 100],
        "kgCO2PerDollar": [0, 1]
    };
    const units = {
        "emissions": "kt CO2e",
        "emissionsPerCapita": "t CO2e",
        "changeSince1990": "%",
        "performanceIndex": "",
        "riskIndex": "",
        "percentageRenewableElectricity": "%",
        "kgCO2PerDollar": "kg/$"
    };
    const explanations = {
        "emissions": {
            explanation: "Total greenhouse gas emissions, measured in megatonnes of CO2 equivalent (Mt CO2e). Colors are assigned based on a logarithmic scale.",
            source: "https://www.worldbank.org/en/topic/climatechange",
            readMore: "https://en.wikipedia.org/wiki/Greenhouse_gas"
        },
        "emissionsPerCapita": {
            explanation: "Greenhouse gas emissions per capita, measured in tonnes of CO2 equivalent (t CO2e).",
            source: "https://www.worldbank.org/en/topic/climatechange",
            readMore: "https://en.wikipedia.org/wiki/Greenhouse_gas"
        },
        "changeSince1990": {
            explanation: "Percentage change in greenhouse gas emissions since 1990.",
            source: "https://www.worldbank.org/en/topic/climatechange",
            readMore: "https://en.wikipedia.org/wiki/Global_warming"
        },
        "performanceIndex": {
            explanation: "Climate performance index, a score out of 200 that evaluates the climate protection performance of 57 countries and the EU.",
            source: "https://www.climate-change-performance-index.org/",
            readMore: "https://en.wikipedia.org/wiki/Climate_change_performance_index"
        },
        "riskIndex": {
            explanation: "Climate risk index, a score out of 200 that evaluates the vulnerability and exposure of countries to extreme weather events due to climate change. In the original, a high score means low risk and vice versa. Here, the scores are reversed for consistency.",
            source: "https://germanwatch.org/en/cri",
            readMore: "https://en.wikipedia.org/wiki/Global_Climate_Risk_Index"
        },
        "percentageRenewableElectricity": {
            explanation: "Percentage of electricity generated from renewable sources. For a full picture, one should also consider the share of renewable energy in total energy consumption, however that data is not available for all countries.",
            source: "https://ourworldindata.org/renewable-energy",
            readMore: "https://en.wikipedia.org/wiki/Renewable_energy"
        },
        "kgCO2PerDollar": {
            explanation: "Amount of CO2 emissions produced per unit of GDP (kg/$). This indicates how efficiently a country uses carbon to generate economic output.",
            source: "https://www.worldbank.org/en/topic/climatechange",
            readMore: "https://en.wikipedia.org/wiki/Carbon_intensity"
        },
        "climateContext": {
            "explanation": "This text was generated using GPT3.5. The goal is to provide deeper insights into the country's climate and environmental landscape. As it was automatically generated it may be subject to inaccuracies or be outdated. If you find any issues, please report them so we can improve the accuracy and relevance of our content."
        }
    };
    const colorScales = {
        "emissions": d3.scaleLog()
            .domain(ranges.emissions)
            .range([colors.light("brown"), colors.dark("brown")]),
        "emissionsPerCapita": d3.scaleLinear()
            .domain(ranges.emissionsPerCapita)
            .range([colors.light("orange"), colors.dark("orange")]),
        "changeSince1990": d3.scaleDiverging(t => d3.interpolateRdYlGn(1 - t))
            .domain([-50, 0, 300]),
        "performanceIndex": d3.scaleLinear()
            .domain(ranges.performanceIndex)
            .range([colors.dark("red"), colors.light("green"), colors.dark("green")]),
        "riskIndex": d3.scaleLinear()
            .domain(ranges.riskIndex)
            .range([colors.light("green"), colors.dark("red")]),
        "percentageRenewableElectricity": d3.scaleLinear()
            .domain(ranges.percentageRenewableElectricity)
            .range([colors.light("red"), colors.green]),
        "kgCO2PerDollar": d3.scaleLinear()
            .domain(ranges.kgCO2PerDollar)
            .range([colors.light("orange"), colors.dark("brown")]),
    };
    const legendColorScales = {
        "emissions": colorScales.emissions,
        "emissionsPerCapita": colorScales.emissionsPerCapita,
        "changeSince1990": colorScales.changeSince1990,
        "performanceIndex": colorScales.performanceIndex,
        "riskIndex": colorScales.riskIndex,
        "percentageRenewableElectricity": colorScales.percentageRenewableElectricity,
        "kgCO2PerDollar": colorScales.kgCO2PerDollar
    };
    const legendTitles = {
        "emissions": "Total Greenhouse Gas Emissions (Mt CO2e)",
        "emissionsPerCapita": "Greenhouse Gas Emissions per Capita (t CO2e)",
        "changeSince1990": "Change in Emissions Since 1990 (%)",
        "performanceIndex": "Climate Performance Index",
        "riskIndex": "Climate Risk Index",
        "percentageRenewableElectricity": "Percentage of Renewable Electricity",
        "kgCO2PerDollar": "Carbon Intensity (kg/$)"
    };
    const isContinuous = {
        "emissions": true,
        "emissionsPerCapita": true,
        "changeSince1990": true,
        "performanceIndex": true,
        "riskIndex": true,
        "percentageRenewableElectricity": true,
        "kgCO2PerDollar": true
    };
    const displayedLinear = {
        "emissions": false,
        "emissionsPerCapita": true,
        "changeSince1990": true,
        "performanceIndex": true,
        "riskIndex": true,
        "percentageRenewableElectricity": true,
        "kgCO2PerDollar": true
    };
    return {
        metrics: metrics,
        ranges: ranges,
        legendRanges: ranges,
        units: units,
        explanations: explanations,
        colorScales: colorScales,
        legendColorScales: legendColorScales,
        legendTitles: legendTitles,
        isContinuous: isContinuous,
        displayedLinear: displayedLinear,
        UI: climateUI
    }
}

function securityConfig() {
    const metrics = [
        "alliance", "globalPeaceIndex", "activeMilitary", "activeMilitaryRelative",
        "militarySpending", "gdpShare", "militarySpendingPerCapita",
        "shareOfGovSpending", "casualties2023",
        "terrorismIndex", "securityContext"
    ];

    // Assuming the ranges and colors need to be defined based on your application's requirements
    const ranges = {
        "globalPeaceIndex": [1, 5], // Example range, adjust based on actual data
        "activeMilitaryRelative": [0, 50], // Example range, adjust based on actual data
        "terrorismIndex": [0, 10], // Example range, adjust based on actual data
        "activeMilitary": [0, 2500000], // Example range, adjust based on actual data
        "militarySpending": [1000000, 1000000000000], // Example range, adjust based on actual data
        "gdpShare": [0, 100], // Example range, adjust based on actual data
        "militarySpendingPerCapita": [0, 10000], // Example range, adjust based on actual data
        "shareOfGovSpending": [0, 100], // Example range, adjust based on actual data
        "casualties2023": [0, 100000], // Example range, adjust based on actual data
        "alliance": ["NATO", "CSTO", "SCO", "OAS", "EU", "AU", "ASEAN"], // Example range, adjust based on actual data
    };

    const units = {
        "globalPeaceIndex": "",
        "activeMilitary": "personnel",
        "activeMilitaryRelative": "%",
        "militarySpending": "$ million",
        "gdpShare": "%",
        "militarySpendingPerCapita": "$ per capita",
        "shareOfGovSpending": "%",
        "casualties2023": "casualties",
        "terrorismIndex": "",
        "alliance": ""
        // Define units for other metrics if needed
    };

    const explanations = {
        "globalPeaceIndex": {
            explanation: "The Global Peace Index (GPI) measures the peacefulness of nations and regions, ranking them based on 23 indicators such as societal safety, conflict levels, and militarization. Lower scores indicate higher peace.",
            source: "https://www.visionofhumanity.org/maps/",
            readMore: "https://en.wikipedia.org/wiki/Global_Peace_Index"
        },
        "activeMilitary": {
            explanation: "The total number of active military personnel in a country's armed forces.",
            source: "https://en.wikipedia.org/wiki/List_of_countries_by_number_of_military_and_paramilitary_personnel",
            readMore: "https://en.wikipedia.org/wiki/List_of_countries_by_number_of_military_and_paramilitary_personnel"
        },
        "activeMilitaryRelative": {
            explanation: "The number of active military personnel relative to the country's population, expressed as a percentage.",
            source: "https://en.wikipedia.org/wiki/List_of_countries_by_number_of_military_and_paramilitary_personnel",
            readMore: "https://en.wikipedia.org/wiki/List_of_countries_by_number_of_military_and_paramilitary_personnel"
        },
        "militarySpending": {
            explanation: "The total annual expenditure on armed forces, including peacekeeping forces, defense ministries, government agencies, paramilitary forces, and space activities.",
            source: "https://www.sipri.org/sites/default/files/2023-04/2304_fs_milex_2022.pdf",
            readMore: "https://en.wikipedia.org/wiki/List_of_countries_by_military_expenditures"
        },
        "gdpShare": {
            explanation: "Military spending as a percentage of the country's Gross Domestic Product (GDP).",
            source: "https://www.sipri.org/sites/default/files/2023-04/2304_fs_milex_2022.pdf",
            readMore: "https://en.wikipedia.org/wiki/List_of_countries_by_military_expenditures"
        },
        "militarySpendingPerCapita": {
            explanation: "The amount of military expenditure per capita, showcasing how much each citizen would be spending on the military if the cost were distributed evenly.",
            source: "https://www.sipri.org/databases/milex",
            readMore: "https://en.wikipedia.org/wiki/List_of_countries_by_military_expenditures"
        },
        "shareOfGovSpending": {
            explanation: "The proportion of government expenditure that is allocated to defense. It indicates the priority of military spending in the overall government budget.",
            source: "https://www.sipri.org/databases/milex",
            readMore: "https://en.wikipedia.org/wiki/List_of_countries_by_military_expenditures"
        },
        "casualties2023": {
            explanation: "Active armed conflicts in 2023, according to the World Population Review.",
            source: "https://worldpopulationreview.com/country-rankings/countries-currently-at-war",
            readMore: "https://en.wikipedia.org/wiki/List_of_ongoing_armed_conflicts"
        },
        "terrorismIndex": {
            explanation: "A score that measures the impact of terrorism activities in a country. Higher scores indicate greater impact.",
            source: "http://visionofhumanity.org/indexes/terrorism-index/",
            readMore: "https://en.wikipedia.org/wiki/Global_Terrorism_Index"
        },
        "alliance": {
            explanation: "Indicates the major international defense or security alliances the country is a part of, such as NATO or the UN.",
            source: "https://foreignpolicy.org.tr/military-alliances-of-the-world/",
            readMore: "https://en.wikipedia.org/wiki/Military_alliance"
        },
        "securityContext": {
            explanation: "This text was generated using GPT3.5. The goal is to provide deeper insights into the country's security situation. As it was automatically generated it may be subject to inaccuracies or be outdated. If you find any issues, please report them so we can improve the accuracy and relevance of our content."
        }
    };

    let legendColorScales = {};
    legendColorScales["alliance"] = d3.scaleOrdinal(d3.schemeTableau10).domain(ranges.alliance);


    // Example color scales
    const colorScales = {
        "globalPeaceIndex": d3.scaleSequential(d3.interpolateReds).domain(ranges.globalPeaceIndex),
        "activeMilitaryRelative": d3.scaleSequential(d3.interpolateBlues).domain(ranges.activeMilitaryRelative),
        "terrorismIndex": d3.scaleSequential(d3.interpolateOranges).domain(ranges.terrorismIndex),
        "activeMilitary": d3.scaleLinear()
            .domain(ranges.activeMilitary)
            .range([colors.light("green", 1), colors.dark("green", 5)]),
        "militarySpending": d3.scaleLog()
            .domain(ranges.militarySpending)
            .range([colors.light("purple", 2), colors.dark("purple", 3)]),
        "gdpShare": d3.scaleSequential(d3.interpolateGreys).domain(ranges.gdpShare),
        "militarySpendingPerCapita": d3.scaleSequential(d3.interpolateTurbo).domain(ranges.militarySpendingPerCapita),
        "shareOfGovSpending": d3.scaleSequential(d3.interpolateViridis).domain(ranges.shareOfGovSpending),
        "casualties2023": d3.scaleLinear()
            .domain([0, 10, 100000])
            .range([colors.light("red", 1), colors.red, colors.red]),
        "alliance": securityUI.getStripedPatternScale
    };

    // rest of legendColorScales is same as colorScales
    for (let metric of metrics) {
        if (metric !== "alliance") {
            legendColorScales[metric] = colorScales[metric];
        }
    }

    legendColorScales["casualties2023"] = undefined;



    const legendTitles = {
        "globalPeaceIndex": "Global Peace Index",
        "activeMilitaryRelative": "Active Military as % of Population",
        "terrorismIndex": "Terrorism Index",
        "activeMilitary": "Active Military",
        "militarySpending": "Military Spending",
        "gdpShare": "GDP Share",
        "militarySpendingPerCapita": "Military Spending Per Capita",
        "shareOfGovSpending": "Share of Military Budget in Government Spending",
        "casualties2023": "Armed Conflicts (2023)",
        "alliance": "Alliance"
    };

    const isContinuous = {
        "globalPeaceIndex": true,
        "activeMilitaryRelative": true,
        "terrorismIndex": true,
        "activeMilitary": true,
        "militarySpending": true,
        "gdpShare": true,
        "militarySpendingPerCapita": true,
        "shareOfGovSpending": true,
        "casualties2023": true,
        "alliance": false
    };

    // Assuming all metrics are displayed linearly for simplicity
    const displayedLinear = metrics.reduce((acc, metric) => ({ ...acc, [metric]: true }), {});
    displayedLinear["militarySpending"] = false;

    return {
        metrics: metrics,
        ranges: ranges,
        legendRanges: ranges,
        units: units,
        explanations: explanations,
        colorScales: colorScales,
        legendColorScales: legendColorScales,
        legendTitles: legendTitles,
        isContinuous: isContinuous,
        displayedLinear: displayedLinear,
        UI: securityUI
    };
}

function economyConfig() {
    const metrics = [
        "gdp", "gdpPerCapita", "unemploymentRate", "lastInflation",
        "govDebt", "gini", "gdpGrowth", "economyContext"
    ];

    const ranges = {
        "gdp": [0, 30000000000000], // Max value based on your CSV example
        "gdpPerCapita": [0, 90000], // Max value approximated
        "unemploymentRate": [0, 30], // Max value approximated
        "lastInflation": [-10, 300], // Considering inflation can be negative
        "govDebt": [0, 200], // Assuming percentage of GDP
        "gini": [20, 70], // GINI index range
        "gdpGrowth": [-10, 10] // GDP growth can be negative for contraction
    };

    const units = {
        "gdp": "$ million",
        "gdpPerCapita": "$",
        "unemploymentRate": "%",
        "lastInflation": "%",
        "govDebt": "% of GDP",
        "gini": "",
        "gdpGrowth": "%"
    };

    const explanations = {
        "gdp": {
            explanation: "The total market value of all final goods and services produced within a country in a specific time period, adjusted for the cost of living in that country.",
            source: "https://en.wikipedia.org/wiki/List_of_countries_by_GDP_(PPP)",
            readMore: "https://en.wikipedia.org/wiki/Gross_domestic_product"
        },
        "gdpPerCapita": {
            explanation: "Gross Domestic Product divided by the number of people in the population, adjusted for the cost of living in that country.",
            source: "https://en.wikipedia.org/wiki/List_of_countries_by_GDP_(PPP)_per_capita",
            readMore: "https://en.wikipedia.org/wiki/Gross_domestic_product"
        },
        "unemploymentRate": {
            explanation: "The percentage of the total labor force that is unemployed but actively seeking employment and willing to work.",
            source: "https://en.wikipedia.org/wiki/List_of_countries_by_unemployment_rate",
            readMore: "https://en.wikipedia.org/wiki/Unemployment"
        },
        "lastInflation": {
            explanation: "The rate of increase in prices over a given period of time.",
            source: "https://tradingeconomics.com/country-list/inflation-rate-",
            readMore: "https://en.wikipedia.org/wiki/Inflation"
        },
        "govDebt": {
            explanation: "The total amount of money that the government owes to creditors.",
            source: "https://en.wikipedia.org/wiki/List_of_countries_by_government_debt",
            readMore: "https://en.wikipedia.org/wiki/Government_debt"
        },
        "gini": {
            explanation: "A measure of the income distribution of a country's residents, and is used as an indicator of economic inequality.",
            source: "https://en.wikipedia.org/wiki/List_of_countries_by_income_equality",
            readMore: "https://en.wikipedia.org/wiki/Gini_coefficient"
        },
        "gdpGrowth": {
            explanation: "The increase in the inflation-adjusted market value of the goods and services produced by an economy over time.",
            source: "https://en.wikipedia.org/wiki/List_of_countries_by_GDP_(PPP)",
            readMore: "https://en.wikipedia.org/wiki/Economic_growth"
        },
        "economyContext": {
            "explanation": "This text was generated using GPT3.5. The goal is to provide deeper insights into the country's economic landscape. As it was automatically generated it may be subject to inaccuracies or be outdated. If you find any issues, please report them so we can improve the accuracy and relevance of our content."
        }
    };

    // Define color scales for each metric
    const colorScales = {
        "gdp": d3.scaleLinear().domain(ranges.gdp).range(["#f0e442", "#9e0142"]),
        "gdpPerCapita": d3.scaleLinear().domain(ranges.gdpPerCapita).range(["#abd9e9", "#f46d43"]),
        "unemploymentRate": d3.scaleLinear().domain(ranges.unemploymentRate).range(["#fee090", "#d73027"]),
        "lastInflation": d3.scaleLinear().domain(ranges.lastInflation).range(["#d9ef8b", "#1a9850"]),
        "govDebt": d3.scaleLinear().domain(ranges.govDebt).range([colors.light("red", 2), colors.dark("red", 2)]),
        "gini": d3.scaleLinear().domain(ranges.gini).range(["#66c2a5", "#fc8d62"]),
        "gdpGrowth": d3.scaleLinear().domain(ranges.gdpGrowth).range(["#3288bd", "#d53e4f"])
    };

    const legendColorScales = {
        ...colorScales
    };

    const legendTitles = {
        "gdp": "GDP (PPP)",
        "gdpPerCapita": "GDP per Capita ($)",
        "unemploymentRate": "Unemployment Rate (%)",
        "lastInflation": "Inflation Rate (%)",
        "govDebt": "Government Debt (% of GDP)",
        "gini": "GINI Index",
        "gdpGrowth": "GDP Growth Rate (%)"
    };

    const isContinuous = {
        "gdp": true,
        "gdpPerCapita": true,
        "unemploymentRate": true,
        "lastInflation": true,
        "govDebt": true,
        "gini": true,
        "gdpGrowth": true
    };

    const displayedLinear = {
        "gdp": true,
        "gdpPerCapita": true,
        "unemploymentRate": true,
        "lastInflation": true,
        "govDebt": true,
        "gini": true,
        "gdpGrowth": true
    };

    return {
        metrics: metrics,
        ranges: ranges,
        legendRanges: ranges,
        units: units,
        explanations: explanations,
        colorScales: colorScales,
        legendColorScales: legendColorScales,
        legendTitles: legendTitles,
        isContinuous: isContinuous,
        displayedLinear: displayedLinear,
        UI: economyUI
    };
}



export const config = {
    democracy: democracyConfig(),
    climate: climateConfig(),
    security: securityConfig(),
    economy: economyConfig()
};