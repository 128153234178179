import { config } from "./TrackConfiguration.js";

export const economyUI = {
    setup: function () {
        // Setup specific to economy track can go here
        // This might include initializing charts or other visual elements that are static throughout the lifecycle of the application
    },

    update: function (data) {
        // Update GDP
        d3.select("#gdp").text(data.gdp ? `$${numeral(data.gdp).format('0.0a')}` : "N/A");
        
        // Update GDP Per Capita
        d3.select("#gdpPerCapita").text(data.gdpPerCapita ? `$${numeral(data.gdpPerCapita).format('0.0a')}` : "N/A");
        
        // Update Unemployment Rate
        d3.select("#unemploymentRate").text(data.unemploymentRate ? `${numeral(data.unemploymentRate).format('0.0')}%` : "N/A");
        
        // Update Last Inflation
        d3.select("#lastInflation").text(data.lastInflation ? `${numeral(data.lastInflation).format('0.0')}%` : "N/A");
        
        // Update Government Debt
        d3.select("#govDebt").text(data.govDebt ? `${numeral(data.govDebt).format('0.0')}% of GDP` : "N/A");
        
        // Update GINI Index
        d3.select("#gini").text(data.gini ? numeral(data.gini).format('0.0') : "N/A");
        
        // Update GDP Growth
        d3.select("#gdpGrowth").text(data.gdpGrowth ? `${numeral(data.gdpGrowth).format('0.0')}%` : "N/A");

        // Any additional visualizations specific to the economy track could be updated here
        // Example for a chart update function might be added here if needed

        // Update the context section
        let insights = data.insights || "N/A";
        if (data.read_more) {
            insights += ` <a href="${data.read_more}" target="_blank">Read more on Wikipedia.</a>`;
        }
        d3.select("#economyContext").html(insights);
    }
}
