import { config } from "./TrackConfiguration.js";
import { colors } from "./colors.js";

export const securityUI = {
    setup: function () {
        // Setup specific to security track can go here
        // This might include initializing static elements or charts that don't need to be updated with new data
    },

    update: function (data) {
        // Update Global Peace Index
        d3.select("#globalPeaceIndex").text(data.globalPeaceIndex ? numeral(data.globalPeaceIndex).format('0.0a') : "N/A");
        
        // Update Active Military Personnel
        d3.select("#activeMilitary").text(data.activeMilitary ? numeral(data.activeMilitary).format('0.0a') : "N/A");
        
        // Update Active Military Relative
        d3.select("#activeMilitaryRelative").text(data.activeMilitaryRelative ? `(${data.activeMilitaryRelative}/1K capita)` : "N/A");
        
        // Update Military Spending
        d3.select("#militarySpending").text(data.militarySpending ? `$${numeral(data.militarySpending).format('0.0a')}` : "N/A");
        
        // Update GDP Share
        d3.select("#gdpShare").text(data.gdpShare ? `${data.gdpShare}%` : "N/A");
        
        // Update Military Spending Per Capita
        d3.select("#militarySpendingPerCapita").text(data.militarySpendingPerCapita ? `$${numeral(data.militarySpendingPerCapita).format('0.0a')}` : "N/A");
        
        // Update Share Of Gov Spending
        d3.select("#shareOfGovSpending").text(data.shareOfGovSpending ? `${data.shareOfGovSpending}%` : "N/A");
        
        // Update Casualties 2023
        d3.select("#activeConflicts").text(data.conflictName || "-");
        //d3.select("#casualties2023").text(data.casualties2023 ? numeral(data.casualties2023).format('0.0a') : "-");
        
        // Update Terrorism Index
        d3.select("#terrorismIndex").text(data.terrorismIndex ? numeral(data.terrorismIndex).format('0.0a') : "N/A");
        
        // Update Alliance Membership
        d3.select("#alliance").text(data.alliance.replace(",", ", ") || "-");

        // Any additional visualizations specific to the security track could be updated here
        let insights = data.insights || "N/A";
        if (data.read_more) {
            insights += ` <a href="${data.read_more}" target="_blank">Read more on Wikipedia.</a>`;
        }
        d3.select("#securityContext").html(insights)
    },

    getStripedPatternScale: getStripedPatternScaleF
}

function getStripedPatternScaleF(allianceString) {
  let alliances = allianceString.split(",");
  
  // if only one alliance give color
  if (alliances.length === 1) {
      return config.security.legendColorScales["alliance"](alliances[0]);
  } else {

      // get colors for alliances
      let colors = alliances.map(alliance => config.security.legendColorScales["alliance"](alliance));

      // add pattern to svg if it doesn't exist
      let svg = d3.select("svg");
      let defs = svg.select("defs");
      if (defs.empty()) {
        defs = svg.append("defs");
      }
      let pattern = defs.select("#pattern-stripe-" + allianceString);
      if (pattern.empty()) {
          pattern = defs.append("pattern")
              .attr("id", "pattern-stripe-" + allianceString)
              .attr("patternUnits", "userSpaceOnUse")
              .attr("width", 8)
              .attr("height", 8)
              .attr("patternTransform", "rotate(45)");

          pattern.append("rect")
              .attr("width", 8)
              .attr("height", 8)
              .attr("transform", "translate(0,0)")
              .attr("fill", colors[0]);

          pattern.append("path")
              .attr("d", "M 0,0 l 8,0")
              .attr("stroke", colors[1])
              .attr("stroke-width", 8);
      }


      return "url(#pattern-stripe-" + allianceString + ")";
  }
}

/* Define a base pattern and then modify it for different trade agreements
function defineStripedPatterns(svg, tradeAgreements) {
    tradeAgreements.forEach((agreement, index) => {
      svg.select('defs')
        .append('pattern')
        .attr('id', `pattern-stripe-${agreement}`)
        .attr('patternUnits', 'userSpaceOnUse')
        .attr('width', 4)
        .attr('height', 4)
        .attr('patternTransform', 'rotate(45)')
        .append('rect')
        .attr('width', 4)
        .attr('height', 4)
        .attr('transform', 'translate(0,0)')
        .attr('fill', agreement.color); // Set the color specific to the trade agreement
  
      svg.select(`#pattern-stripe-${agreement}`)
        .append('path')
        .attr('d', 'M 0,0 l 4,0')
        .attr('stroke', agreement.stripeColor) // Set stripe color
        .attr('stroke-width', 1);
    });
  }
  
  // Example usage
  const svg = d3.select('svg');
  defineStripedPatterns(svg, [
    { name: 'NAFTA', color: 'blue', stripeColor: 'white' },
    { name: 'EU', color: 'yellow', stripeColor: 'blue' },
    // ...add more trade agreements as needed
  ]);*/

// You may want to create specific visualization functions similar to `drawPie` for the climate track
// Example: a function to display military spending as a portion of GDP or to visualize the global peace index
