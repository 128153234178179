import { config } from "./TrackConfiguration.js";
import { FLAGS_PATH } from "./constants.js";


async function getWikipediaLink(wikidataID) {
  try {
    const response = await fetch(`https://www.wikidata.org/w/api.php?action=wbgetentities&ids=${wikidataID}&props=sitelinks/urls&format=json&origin=*`);
    const data = await response.json();
    const englishWikipediaUrl = data.entities[wikidataID].sitelinks.enwiki.url;
    return englishWikipediaUrl;
  } catch (error) {
    console.error(error);
    return null;
  }
}

// Create a custom event
const factClickedEvent = new CustomEvent('factClicked', {
  detail: { metric: null },  // The metric will be set when a .fact div is clicked
});

export class InfoBox {
  constructor(track) {
    this.track = track;
    for (let track in config) {
      config[track].UI.setup();
    }
    // add click listeners to .fact divs
    d3.selectAll(".fact").on("click", (event, d) => {
      let metric = event.currentTarget.getAttribute("metric");

      // if metric contains "context", do not dispatch the event
      if (metric.includes("Context")) return;

      factClickedEvent.detail.metric = metric;
      window.dispatchEvent(factClickedEvent);

      // reset background color of all divs
      d3.selectAll(".fact").style("background", "white");
      // change background color of the clicked div
      event.currentTarget.style.backgroundColor = "#ffffffbb";

      // Select all .fact-icon-label divs within .fact divs
      const factIconLabels = document.querySelectorAll('.fact .fact-icon-label');

      // Iterate over the selected divs
      factIconLabels.forEach((label) => {
        // Get the parent .fact div
        const factDiv = label.closest('.fact');

        // Get the metric attribute
        const metric = factDiv.getAttribute('metric');

        // Get the corresponding tooltip in one of the tracks
        let tooltipData;
        for (let track in config) {
          if (config[track].metrics.includes(metric)) {
            tooltipData = config[track].explanations[metric];
            break;
          }
        }

        if (!tooltipData) return;

        // Create the tooltip content
        let tooltipContent = `${tooltipData.explanation}`

        if (tooltipData.readMore || tooltipData.source) {
          tooltipContent += `<div><a class="tooltip-link" href="${tooltipData.readMore}" target="_blank">Read More</a> / <a class="tooltip-link" href="${tooltipData.source}" target="_blank">Data Source</a></div>`;
        } 

        // Initialize the tooltip
        tippy(label, {
          content: tooltipContent,
          allowHTML: true,  // Allow HTML in the tooltip content
          interactive: true, // Make the tooltip interactive
          trigger: 'click', // Show the tooltip on click
          placement: 'left', // Position the tooltip below the element
        });

        // Add a click event listener to the .fact-icon-label div
        label.addEventListener('click', (event) => {
          event.stopPropagation();  // Prevent the click event from propagating to the .fact div
        });
      });
    });
  }

  update(data) {
    this.updateBasicInfo(data["general"]);
    for (let track in config) {
      config[track].UI.update(data[track]);
    }
  }

  updateBasicInfo(countryData) {
    const wikipediaLink = getWikipediaLink(countryData.wikidata);
    d3.select("#countryFlag").attr("src", `${FLAGS_PATH}/${countryData.code2}.svg`);
    d3.select("#countryName").text(countryData.name || "N/A")
      .on("click", () => {
        wikipediaLink.then(link => window.open(link, "_blank"));
      });
    d3.select("#countryCapital").text(countryData.capital || "N/A");
    d3.select("#countryPopulation").text(countryData.population ? numeral(countryData.population).format('0.0a') : "N/A");
    d3.select("#countryArea").text(countryData.area ? numeral(countryData.area).format('0.0a') + " km²" : "N/A");
  }

  setTrack(track) {
    this.track = track;
  }


}