import { FLAGS_PATH, OPACITY, OPACITY_CLICKED, OPACITY_HOVER } from "./constants.js";
import { InfoBox } from "./InfoBox.js";
import { config } from "./TrackConfiguration.js";

export class WorldMap {
  constructor(topo, data, track) {
    this.topo = topo;
    this.data = data;
    this.track = track;
    this.clickedCountry = null;
    this.projection = this.setupProjection()
    this.path = d3.geoPath().projection(this.projection);
    this.tooltip = this.createTooltip();
    this.svg = d3.select("#map");
    this.infoBox = new InfoBox(track);
    this.countries = this.svg.append("g").attr("id", "countries");
  }

  setupProjection() {
    return d3.geoMercator()
      .center([0, 40])
      .rotate([-10, 0]);
  }

  draw() {
    this.countries
      .selectAll("path")
      .data(this.topo.features)
      .join("path")
      .attr("d", d3.geoPath().projection(this.projection))
      .attr("fill", "black")
      .style("stroke", "white")
      .style("stroke-width", "0.5px")
      .attr("class", "Country")
      .style("opacity", OPACITY)
      .attr("id", d => "map-country-" + d.properties.iso_a3_eh)
      .on("mouseenter", (event, d) => this.handleMouseEnter(event, d))
      .on("mousemove", (event, d) => this.handleMouseMove(event, d))
      .on("mouseleave", (event, d) => this.handleMouseLeave(event, d))
      .on("click", (event, d) => this.handleClick(event, d, this.data));

    // Listen for the custom event
    window.addEventListener('factClicked', (event) => {
      this.setMapColor(event.detail.metric, this.data);
    });


    const transf = this.getTransformation();
    const bbox = this.countries.node().getBBox();

    const zoom = d3.zoom().scaleExtent([transf.scale, 8]).translateExtent([[bbox.x-50, bbox.y-50], [bbox.x+bbox.width+50, bbox.y+bbox.height+50]])
      .on('zoom', (event) => this.zoomed(event));
    const initialTransform = d3.zoomIdentity.scale(transf.scale).translate(transf.translateX, transf.translateY);
    this.svg.call(zoom).call(zoom.transform, initialTransform);
    

    window.addEventListener("resize", () => this.getTransformation()); //FIX

    /*this.countries.append("rect")
      .attr("x", bbox.x-40)
      .attr("y", bbox.y-40)
      .attr("width", 100)
      .attr("height", 100)
      .attr("fill", "red");

    this.countries.append("rect")
      .attr("x", bbox.x + bbox.width +40 - 100)
      .attr("y", bbox.y + bbox.height +40 - 100)
      .attr("width", 100)
      .attr("height", 100)
      .attr("fill", "blue");*/

    // dispatch click event for default metric for current track
    let defaultMetric = config[this.track].metrics[0];
    d3.select(`.fact[metric='${defaultMetric}']`).dispatch("click");

    // dispatch click event for austria
    d3.selectAll(".Country").filter(d => d.properties.iso_a2_eh === "AT").dispatch("click");

  }




  zoomed(event) {
    // Destructuring the current transform
    //let { x, y, k } = event.transform;

    // Scale extent (how much you can zoom in and out)
    //const scaleExtent = [1, 8];
    /*k = Math.max(scaleExtent[0], Math.min(scaleExtent[1], k)); // Ensure k is within the bounds

    // SVG dimensions*/
    //const { width, height, top, left } = this.svg.node().getBoundingClientRect();
    /*// this.svg.node().getBBBox()

    // Calculate the bounds of the SVG content
    const xMin = 0;
    const xMax = width;
    const yMin = 0
    const yMax = height;

    // Calculate the adjusted bounds based on the current zoom level
    const xMinAdjusted = xMin * k + (width - width * k) / 2;
    const xMaxAdjusted = xMax * k - (width - width * k) / 2;
    const yMinAdjusted = yMin * k + (height - height * k) / 2;
    const yMaxAdjusted = yMax * k - (height - height * k) / 2;

    // Enforce the pan and zoom boundaries
    const newX = Math.max(xMinAdjusted, Math.min(xMaxAdjusted, x));
    const newY = Math.max(yMinAdjusted, Math.min(yMaxAdjusted, y));

    // Apply the transform to the SVG content*/
    //this.countries.attr('transform', `translate(${x+width/2},${y+height/2}) scale(${k})`);
    this.countries.attr('transform', event.transform);
  }



  createTooltip() {
    return d3.select("body").append("div")
      .attr("class", "country-tooltip")
      .style("opacity", 0);
  }

  createLegend(title, colorScale, labels, isContinuous = false, displayedLinear = true) {
    // Remove any existing legend and gradient
    this.svg.select(".legend").remove();
    this.svg.select("#legendGradient").remove();

    // Define the legend
    const svgHeight = this.svg.node().getBoundingClientRect().height;
    const legendWidth = 300; // Adjust as needed
    const legend = this.svg.append("g")
      .attr("class", "legend")

    if (colorScale == undefined) {
      // Add the legend title
      legend.attr("transform", `translate(20,${svgHeight - 35}) scale(0.7)`); // Adjust as needed
      legend.append("text")
        .text(title)
        .style("font-size", "18px")
        .attr("transform", "translate(0,25)"); // Adjust as needed
    } else if (isContinuous) {
      legend.attr("transform", `translate(20,${svgHeight - 35}) scale(0.7)`); // Adjust as needed
      // Create a gradient legend for continuous scales
      const gradientId = "legendGradient";

      let defs = this.svg.select("defs");
      if (defs.empty()) {
        defs = this.svg.append("defs");
      }

      const gradient = defs
        .append("linearGradient")
        .attr("id", gradientId)
        .attr("x1", "0")
        .attr("y1", "0")
        .attr("x2", "1")
        .attr("y2", "0");

      labels.forEach((label, i) => {
        gradient.append("stop")
          .attr("offset", `${(i / (labels.length - 1)) * 100}%`)
          .attr("stop-color", colorScale(label));
      });

      legend.append("rect")
        .attr("width", legendWidth)
        .attr("height", 15) // Adjust as needed
        .style("fill", `url(#${gradientId})`);

      // Create a scale for the legend
      let legendScale;
      if (!displayedLinear) {
        legendScale = d3.scaleLog()
          .domain(d3.extent(labels.filter(label => label > 0)))  // filter out 0 or negative values
          .range([10, legendWidth - 10]);
      } else {
        legendScale = d3.scaleLinear()
          .domain(d3.extent(labels))
          .range([10, legendWidth - 10]);
      }


      // Determine the number of ticks based on the maximum value
      const maxVal = d3.max(colorScale.domain());
      const numTicks = maxVal > 1000 ? 3 : 5;


      // Create an axis for the legend
      const legendAxis = d3.axisBottom(legendScale)
        .ticks(numTicks).tickSize(15)
        .tickFormat(d => {
          //return d3.format(".0f")(d);
          let formattedWithDecimal = numeral(d).format('0.0a');
          let formattedWithoutDecimal = numeral(d).format('0a');

          // Check if the decimal part is non-zero
          if (formattedWithDecimal.split('.')[1][0] !== '0') {
            return formattedWithDecimal;
          } else {
            return formattedWithoutDecimal;
          }
        });

      // Add the legend axis
      const legendAxisGroup = legend.append("g")
        .attr("transform", "translate(0,0)") // Adjust as needed
        .call(legendAxis);

      legendAxisGroup.selectAll(".tick text")  // Select the tick text elements
        .style("font-size", "14px")  // Increase the font size
        // move down
        .attr("transform", "translate(0,3)");

      legendAxisGroup.selectAll(".tick line")
        .style("stroke", "white");
      legendAxisGroup.select(".domain")
        .style("stroke", "white");

      // Add the legend title
      legend.append("text")
        .text(title)
        .style("font-size", "18px")
        .attr("transform", "translate(0,-10)"); // Adjust as needed
    } else {
      legend.attr("transform", `translate(20,${svgHeight - (labels.length * 20)}) scale(0.7)`); // Adjust as needed
      // Create a categorical legend for ordinal scales
      labels.forEach((label, i) => {
        const height = 20; // Adjust as needed
        const width = 20; // Adjust as needed
        const spacing = 25; // Adjust as needed

        legend.append("rect")
          .attr("x", 0)
          .attr("y", i * spacing)
          .attr("width", width)
          .attr("height", height)
          .style("fill", colorScale(label));

        legend.append("text")
          .attr("x", width + 5) // 5 is the spacing between the rect and the text
          .attr("y", i * spacing + height / 2)
          .text(label)
          .attr("dominant-baseline", "middle");
      });

      // Add the legend title
      legend.append("text")
        .text(title)
        .style("font-size", "18px")
        .attr("transform", "translate(0,-10)"); // Adjust as needed
    }
  }

  getTransformation() {
    const width = this.svg.node().getBoundingClientRect().width;
    const height = this.svg.node().getBoundingClientRect().height;

    const initialWidth = d3.select("#countries").node().getBoundingClientRect().width;
    const initialHeight = d3.select("#countries").node().getBoundingClientRect().height;

    const paddingX = 40;
    const paddingY = 40;

    const offsetY = 120;

    // depending on whether the width or height is the limiting factor, we need to adjust the scale
    const scale = Math.min(width / (initialWidth + paddingX), height / (initialHeight+paddingY));

    // calculate the new dimensions
    const newWidth = initialWidth * scale;
    const newHeight = initialHeight * scale;

    // calculate the new position
    const x = (width - newWidth) / 2;
    const y = (height - newHeight + offsetY) / 2;


    return {
      translateX: x,
      translateY: y,
      width: newWidth,
      height: newHeight,
      scale: scale
    };
  }

  handleMouseEnter(event, d) {
    // Apply stroke to current country
    d3.select(event.currentTarget)
      .transition()
      .duration(200)
      .style("opacity", OPACITY_HOVER)

    this.tooltip.html(`<div class="tooltip-content"><img src="${FLAGS_PATH}/${d.properties.iso_a2_eh}.svg" class="country-tooltip-flag" /> ${d.properties.name}</div>`)
      .style("left", event.clientX + "px")
      .style("top", event.clientY - 12 + "px")
      .style("transform", "translate(-50%, -100%)")
      .style("opacity", 1);
  }

  handleMouseMove(event, d) {
    this.tooltip.style("left", event.clientX + "px")
      .style("top", event.clientY - 12 + "px")
      .style("transform", "translate(-50%, -100%)");
  }

  handleMouseLeave(event, d) {
    this.tooltip.style("opacity", 0);
    if (this.clickedCountry !== d) {
      d3.select(event.currentTarget)
        .transition()
        .duration(200)
        .style("opacity", OPACITY)
    }
  }

  handleClick(event, d, data) {
    event.stopPropagation();

    this.clickedCountry = d;

    // change opacity to 0.8
    d3.selectAll(".Country")
      .transition()
      .duration(200)
      .style("opacity", OPACITY)

    d3.select(event.currentTarget)
      .transition()
      .duration(200)
      .style("opacity", OPACITY_CLICKED)

    // Update placeholders with country data

    let countryData = this.data.get(d.properties.iso_a3_eh);
    this.infoBox.update(countryData);
  }

  setMapColor(metric, data) {
    let colorScale = config[this.track].colorScales[metric];
    let legendColorScale = config[this.track].legendColorScales[metric];
    let range = config[this.track].ranges[metric];
    let legendRange = config[this.track].legendRanges[metric];
    let title = config[this.track].legendTitles[metric];
    let isContinuous = config[this.track].isContinuous[metric];
    let displayedLinear = config[this.track].displayedLinear[metric];


    this.createLegend(title, legendColorScale, legendRange, isContinuous, displayedLinear);
    d3.selectAll(".Country")
      .attr("fill", (d) => {

        let countryData = data.get(d.properties.iso_a3_eh);
        countryData = countryData ? countryData[this.track] : null;
        let metricValue = countryData ? countryData[metric] : 0;
        if (metric == "activeMilitary") {
        }

        return metricValue ? colorScale(metricValue) : "#f2f2f2";
      });
  }

  setTrack(track) {
    this.track = track;
    // dispatch click event for default metric for current track
    let defaultMetric = config[this.track].metrics[0];
    d3.select(`.fact[metric='${defaultMetric}']`).dispatch("click");
    this.infoBox.setTrack(track);
  }

}
