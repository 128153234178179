import { WorldMap } from './WorldMap.js';
import { colors } from './colors.js';

window.onload = function() {
  document.body.style.opacity = 1;
  setScrollableContainerHeight();
};

window.onresize = function() {
  setScrollableContainerHeight
}

function setScrollableContainerHeight() {
  var mapHeight = document.querySelector('svg#map').clientHeight; // Replace 'svg#map' with your map's actual ID or class
  var countryHeaderHeight = document.querySelector('#countryHeader').clientHeight;
  var scrollableContainer = document.querySelector('.scrollable-container');

  // Set the height of the scrollable container
  scrollableContainer.style.height = (mapHeight - countryHeaderHeight) -17 + 'px';
}

const driver = window.driver.js.driver;

const driverObj = driver({
  showProgress: false, // Show the progress bar in the popover
  popoverClass: 'intro-popover', // Custom class for the popover
  smoothScroll: true, // Smooth scroll to highlighted element
  allowClose: false, // Allow the popover to be closed
  steps: [
    {
      popover: {
        title: 'Welcome',
        description: 'This quick tour will guide you through the main features of this interactive world map. Click "Next" to start.'
      }
    },
    {
      element: '#map',
      popover: {
        title: 'World Map',
        description: 'This is the heart of our application. Here you can explore global data by hovering over or clicking on any country.'
      }
    },
    {
      element: '.menu',
      popover: {
        title: 'Track Selection',
        description: 'Use these options to select the data track you are interested in: Democracy, Security, Climate, or Economy. Selecting a track changes the information displayed on the map.'
      }
    },
    {
      element: '#countryDetails',
      popover: {
        title: 'Sidebar',
        description: 'This sidebar displays detailed information about the selected country, including key metrics, trends, and insights.'
        // description: 'After selecting a country, you can find an expanded view here, with in-depth analysis, additional metrics, and historical data visualization.'
      }
    },
    {
      element: '#map-country-CHN',
      popover: {
        title: 'Countries',
        description: 'Clicking on a country, like China here, will show you detailed information for this country on the selected track.'
      }
    },
    {
      element: '.fact:nth-child(2)',
      popover: {
        title: 'Fact Selection',
        description: 'Click on a different metric to see it visualized on the map. The selected metric will be highlighted in the sidebar. By default, the first metric is visualized.'
      }
    },
    {
      element: '.fact-icon-label',
      popover: {
        title: 'Fact Details',
        description: 'In case the meaning of a specific metric is unclear, click on the label to get a detailed explanation, links to further reading, and the data source.'
      }
    },
    {
      element: '#countryName',
      popover: {
        title: 'Country Info',
        description: 'If you want to find out more about this country, click on the country name to open its Wikipedia page in a new tab.'
      }
    },
    {
      element: '#map',
      popover: {
        title: 'Zooming',
        description: 'You can zoom in and out of the map using the scroll wheel on your mouse or by pinching on a touch screen/pad.'
      }
    },
    {
      element: '.fact.non-clickable[metric="democracyContext"]',
      popover: {
        title: 'Disclaimer', // about AI-generated Brief Insight
        description: 'The Brief Insight aims to give a full picture of the country, enriching the data with additional context. It is generated by GPT-3.5, an AI large language model, so it is important to consider that it may not always be accurate. Please report any inaccuracies you may find and double-check data from reliable sources.'
      }
    },
    {
      popover: {
        title: 'You’re All Set!',
        description: 'You’re now ready to explore the data and uncover insights about the world. Don’t forget, you can always change tracks or select another country to learn more. Enjoy your journey through Moment of Insight!'
      }
    }
  ],
  onDestroyed: () => {
    localStorage.setItem('hasSeenTour-1.1', true);
  }
});


if (!localStorage.getItem('hasSeenTour-1.1')) {
  driverObj.drive();
}



// Tooltip configuration
configureTooltip();

// Numeral locale configuration
configureNumeralLocale();

// Define the color scales and background colors
const tracks = {
  'democracy': { backgroundColor: colors.light("purple", 0.1) },
  'climate': { backgroundColor: colors.dark("green", 0) },
  'security': { backgroundColor: colors.light("blue", 0.1) },
  'economy': { backgroundColor: colors.light("brown", 0.1) },
};

document.documentElement.style.setProperty('--democracy-color', tracks['democracy'].backgroundColor);
document.documentElement.style.setProperty('--climate-color', tracks['climate'].backgroundColor);
document.documentElement.style.setProperty('--security-color', tracks['security'].backgroundColor);
document.documentElement.style.setProperty('--economy-color', tracks['economy'].backgroundColor);

//------------------------------------------------------------

let map;
let defaultTrack = "democracy";

async function main() {
  // click on democracy track by default
  d3.select(`.menu-item[data-track="${defaultTrack}"]`).dispatch("click");
  const { geoJSON, data } = await loadData();
  const dataByCode = processData(data, geoJSON);
  setup(geoJSON, dataByCode, defaultTrack);
}

async function loadData() {
  const geoJSON = await d3.json("data/world-s.geo.json");
  let data = {
    general: await d3.csv("data/general.csv"),
    democracy: await d3.csv("data/democracy.csv"),
    climate: await d3.csv("data/climate.csv"),
    security: await d3.csv("data/security.csv"),
    economy: await d3.csv("data/economy.csv"),
    democracy_insights: await d3.json("data/democracy-insights.json"),
    climate_insights: await d3.json("data/climate-insights.json"),
    security_insights: await d3.json("data/security-insights.json"),
    economy_insights: await d3.json("data/economy-insights.json"),
  }
  return { geoJSON, data };
}

function processData(data, topo) {
  const dataByCode = new Map();

  data['general'].forEach(d => {
    dataByCode.set(d.code, { 'general': d });
  });

  data['democracy'].forEach(d => {
    if (!dataByCode.has(d.code)) {
      dataByCode.set(d.code, { 'democracy': d });
    } else {
      let country = dataByCode.get(d.code);
      country['democracy'] = d;
    }
  });

  data['democracy_insights'].forEach(d => {
    // add text and read_more to the democracy data
    if (!dataByCode.has(d.code)) {
      dataByCode.set(d.code, { 'democracy': { 'insights': d.text, 'read_more': d.read_more } });
    } else
    if (dataByCode.has(d.code)) {
      let country = dataByCode.get(d.code);
      if (!country['democracy']) {
        country['democracy'] = {};
      }
      country['democracy']['insights'] = d.text;
      country['democracy']['read_more'] = d.read_more;
    }
  });

  data['climate'].forEach(d => {
    if (!dataByCode.has(d.code)) {
      dataByCode.set(d.code, { 'climate': d });
    } else {
      let country = dataByCode.get(d.code);
      country['climate'] = d;
    }
  });

  data['climate_insights'].forEach(d => {
    // add text and read_more to the climate data
    if (!dataByCode.has(d.code)) {
      dataByCode.set(d.code, { 'climate': { 'insights': d.text, 'read_more': d.read_more } });
    } else
    if (dataByCode.has(d.code)) {
      let country = dataByCode.get(d.code);
      if (!country['climate']) {
        country['climate'] = {};
      }
      country['climate']['insights'] = d.text;
      country['climate']['read_more'] = d.read_more;
    }
  });

  data['security'].forEach(d => {
    if (!dataByCode.has(d.code)) {
      dataByCode.set(d.code, { 'security': d });
    } else {
      let country = dataByCode.get(d.code);
      country['security'] = d;
    }
  });

  data['security_insights'].forEach(d => {
    // add text and read_more to the security data
    if (!dataByCode.has(d.code)) {
      dataByCode.set(d.code, { 'security': { 'insights': d.text, 'read_more': d.read_more } });
    } else
    if (dataByCode.has(d.code)) {
      let country = dataByCode.get(d.code);
      if (!country['security']) {
        country['security'] = {};
      }
      country['security']['insights'] = d.text;
      country['security']['read_more'] = d.read_more;
    }
  });

  data['economy'].forEach(d => {
    if (!dataByCode.has(d.code)) {
      dataByCode.set(d.code, { 'economy': d });
    } else {
      let country = dataByCode.get(d.code);
      country['economy'] = d;
    }
  });

  data['economy_insights'].forEach(d => {
    // add text and read_more to the economy data
    if (!dataByCode.has(d.code)) {
      dataByCode.set(d.code, { 'economy': { 'insights': d.text, 'read_more': d.read_more } });
    } else
    if (dataByCode.has(d.code)) {
      let country = dataByCode.get(d.code);
      if (!country['economy']) {
        country['economy'] = {};
      }
      country['economy']['insights'] = d.text;
      country['economy']['read_more'] = d.read_more;
    }
  });

  // for each country also add the name from the topojson and the iso_a2 code, fix that maybe later to be included in the csv directly
  dataByCode.forEach((value, key) => {
    let country = topo.features.find(f => f.properties.iso_a3_eh === key);
    if (country && value['general']) {
      value['general'].name = country.properties.name;
      value['general'].code2 = country.properties.iso_a2_eh;
    } else if (country) {
      value['general'] = { name: country.properties.name, code2: country.properties.iso_a2_eh };
    }
  });

  return dataByCode;
}

function setup(geoJSON, dataByCode) {
  map = new WorldMap(geoJSON, dataByCode, defaultTrack);
  map.draw();
  setTrack(defaultTrack);
}

function setTrack(track) {
  // Get the color scale and background color for the selected track
  const { backgroundColor } = tracks[track];
  document.body.style.backgroundColor = backgroundColor;
  document.documentElement.style.setProperty('--primary-color', backgroundColor);

  d3.selectAll(`.track-details:not([data-track="${track}"])`).classed('active', false);
  d3.select(`.track-details[data-track="${track}"]`).classed('active', true);

  // Update the track variable
  track = track.toLowerCase();
  if (map) map.setTrack(track);
}

// Add click event listeners to the track switch elements
document.querySelectorAll('.menu-item').forEach((trackSwitch) => {
  trackSwitch.addEventListener('click', async (event) => {
    // Remove 'active' class from all menu items
    document.querySelectorAll('.menu-item').forEach((item) => {
      item.classList.remove('active');
    });

    // Add 'active' class to the clicked menu item
    event.currentTarget.classList.add('active');


    let track = event.currentTarget.getAttribute('data-track') ?? defaultTrack;
    setTrack(track);
  });
});

main();


/*** FUNCTIONS */

function configureTooltip() {
  tippy('.tooltip', {
    content: (reference) => reference.getAttribute('data-tooltip'),
  });
}

function configureNumeralLocale() {
  numeral.register('locale', 'custom', {
    delimiters: {
      thousands: ',',
      decimal: '.'
    },
    abbreviations: {
      thousand: 'K',
      million: 'M',
      billion: 'B',
      trillion: 'T'
    },
    ordinal: function (number) {
      return number === 1 ? 'er' : 'ème';
    },
    currency: {
      symbol: '€'
    }
  });

  // switch between locales
  numeral.locale('custom');
}

